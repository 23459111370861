
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://244597332e474a0cae62fe5bcd78439f@sentry.wixpress.com/2364',
      id: '244597332e474a0cae62fe5bcd78439f',
      projectName: 'express-checkout-widget-ooi',
      teamName: 'payments',
      errorMonitor: true,
    };

  var experimentsConfig = null;

  var translationsConfig = {"enabled":false,"icuEnabled":true};

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "express-checkout-widget-ooi",
    biConfig: null,
    appName: "express-checkout-widget-ooi",
    appDefinitionId: "47e245ca-1a42-4d6a-a69a-c125bc839b40",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: null,
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          isUsingAppSettings: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/src/components/ExpressCheckoutWidget/controller.ts",
          appName: "express-checkout-widget-ooi",
          appDefinitionId: "47e245ca-1a42-4d6a-a69a-c125bc839b40",
          projectName: "express-checkout-widget-ooi",
          componentName: "ExpressCheckoutWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "54fb025c-61dc-4286-87c7-0ac416c58744" }],
    false);

    export const createControllers = _createControllers
